export const fieldTypeEnum = {
  TYPE: "type",
  TYPE_PROPULSION: "type-propulsion"
}

export const typeMaterielEnum = {
  ENGIN: "engin",
  MATERIEL: "materiel"
}

export const typePropulsionEnum = {
  ELECTRIQUE: "electrique",
  HYDROGENE: "hydrogene"
}
