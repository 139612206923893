import React from "react";

import {useAgenceInfo} from "../../hooks/useAgenceInfo";
import IdentityForm from "../contact/identityForm/identityForm";
import SectionContact from "../contact/sectionContact";
import backgroundSection from "../../images/breez/contact.webp";


export default function Step2({next, previous, informations, handleChange, formError, isLoading = false}) {

    const agence = useAgenceInfo(informations["code-postal"]);
    const voyelles = ["a", "e", "i", "o", "u", "y"];

    if (agence) {
        const code = agence?.Codes_Postaux[0]?.Codes?.find((c) => c?.Code === informations["code-postal"]);

        if (code && code?.Inspecteur_IARD?.Email) {
            informations.destinataire = code?.Inspecteur_IARD?.Email;
            informations.unknown_cp = false;
        } else {
            informations.destinataire = "courtage@auxiliaire.fr";
            informations.unknown_cp = true;
        }
        informations['nom-agence'] = (voyelles.includes(agence.Nom[0].toLowerCase()) ? "d'" : "de ") + agence.Nom;
    } else {
        informations.destinataire = "courtage@auxiliaire.fr"
        informations.unknown_cp = true;
    }

    return (
        <div className="basic">
            <SectionContact backgroundImg={backgroundSection}/>
            <IdentityForm
                next={next}
                previous={previous}
                button={true}
                handleChange={handleChange}
                formError={formError}
                isLoading={isLoading}
                showFinaliteTraitementDonnees={true}
            />
        </div>
    );


}
