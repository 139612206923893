import React from "react";

import SectionContact from "../contact/sectionContact";
import ContactEnd from "../contact/contactEnd/contactEnd";

import iconEngin from "../../images/breez/engin.svg";
import iconMateriel from "../../images/breez/materiel.svg";
import iconElectrique from "../../images/breez/electrique.svg";
import iconHydrogene from "../../images/breez/hydrogene.svg";

import InputCode from "../../components/inputCode/inputCode";
import backgroundSection from "../../images/breez/contact.webp";
import {fieldTypeEnum, typeMaterielEnum, typePropulsionEnum} from "./contactBreezEnums";

export default function Step1({ next, informations, handleChange, setCodePostal }) {

  return (
    <div className="contact__step--1">
      <SectionContact backgroundImg={backgroundSection} />
      <section className="contact__section contact__section--2">
        <h1>
          <strong>Être</strong><br></br> rappelé&nbsp;!
        </h1>
        <form
          className="contact__form form"
          onSubmit={(e) => {
            e.preventDefault();
            // On ajoute la vérification du code postal
            if(informations["code-postal"] && informations["code-postal"].length === 5) {
              next();
            }
          }}
        >
          <div className="contact__form-input">
            <span className="contact__title">Vous souhaitez assurer</span>
            <div className="contact__radio">
              <label htmlFor="engin">
                <input
                  type="checkbox"
                  name={fieldTypeEnum.TYPE}
                  value={typeMaterielEnum.ENGIN}
                  id={typeMaterielEnum.ENGIN}
                  onClick={handleChange}
                  checked={informations && informations[fieldTypeEnum.TYPE]?.some(item => item === typeMaterielEnum.ENGIN)}
                />
                <div className="contact__radio-box">
                  <div>
                    <img src={iconEngin} alt="icone engin" />
                    <span>un engin</span>
                  </div>
                </div>
              </label>
              <label htmlFor="materiel">
                <input
                  type="checkbox"
                  name={fieldTypeEnum.TYPE}
                  value={typeMaterielEnum.MATERIEL}
                  id={typeMaterielEnum.MATERIEL}
                  onClick={handleChange}
                  checked={informations && informations[fieldTypeEnum.TYPE]?.some(item => item === typeMaterielEnum.MATERIEL)}
                />
                <div className="contact__radio-box">
                  <div>
                    <img src={iconMateriel} alt="icone matériel" />
                    <span>du matériel</span>
                  </div>
                </div>
              </label>
            </div>
          </div>

          {informations?.type?.length > 0 && (
            <>
          <div className="contact__form-input">
            <span className="contact__title">à propulsion</span>
            <div className="contact__radio">
              <label htmlFor="electrique">
                <input
                  type="checkbox"
                  name={fieldTypeEnum.TYPE_PROPULSION}
                  value={typePropulsionEnum.ELECTRIQUE}
                  id={typePropulsionEnum.ELECTRIQUE}
                  onClick={handleChange}
                  checked={informations && informations[fieldTypeEnum.TYPE_PROPULSION]?.some(item => item === typePropulsionEnum.ELECTRIQUE)}
                />
                <div className="contact__radio-box">
                  <div>
                    <img src={iconElectrique} alt="icone electrique" />
                    <span>éléctrique
                    </span>
                  </div>
                </div>
              </label>
              <label htmlFor="hydrogene">
                <input
                  type="checkbox"
                  name={fieldTypeEnum.TYPE_PROPULSION}
                  value={typePropulsionEnum.HYDROGENE}
                  id={typePropulsionEnum.HYDROGENE}
                  onClick={handleChange}
                  checked={informations && informations[fieldTypeEnum.TYPE_PROPULSION]?.some(item => item === typePropulsionEnum.HYDROGENE)}
                />
                <div className="contact__radio-box">
                  <div>
                    <img src={iconHydrogene} alt="icone hydrogène" />
                    <span>hydrogène</span>
                  </div> 
                </div>
              </label>
            </div>
          </div>
          </>)}
          {informations && informations['type-propulsion']?.length > 0 &&
              <div className="contact__form-input">
                <label htmlFor="code-postal">mon code postal</label>
                <InputCode
                  length={5}
                  onComplete={code => {
                    setCodePostal(code)
                  }}
                />
              </div> 
          }
          <ContactEnd
            step="1"
            informations={informations}
            isBreez={true}
          />
        </form>
      </section>
    </div>
  );
}
