import React, {useState} from "react";
import { submit } from "../../utils/submit";

import closeIcon from "../../images/button/close.svg";

import Step1 from "./step1";

import SectionContact from "../contact/sectionContact";
import backgroundSection from "../../images/breez/contact.webp";
import ContactEnd from "../contact/contactEnd/contactEnd";
import LinkedinCard from "../card/linkedinCard/linkedinCard";

import Step2 from "./step2";
import {IdentityFieldEnum} from "../contact/identityForm/identityFieldEnum";
import {fieldTypeEnum, typeMaterielEnum, typePropulsionEnum} from "./contactBreezEnums";

export default function ContactBreez({ setShowContact, isShowContact }) {
  const [informations, setInformations] = useState({code:"contact-breez"});
  const [step, setStep] = useState(1);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setCodePostal = (code) => {
    setInformations((prevInfo) => ({
      ...prevInfo,
      'code-postal':code?.trim(),
    }));
  }

  const handleChange = (e, type) => {

    if(step === 1){

      if(informations[fieldTypeEnum.TYPE]?.some(item => item === e.target.id)) {
        step1TypeMode(e);
      } else if(informations && informations[fieldTypeEnum.TYPE_PROPULSION]?.some(item => item === e.target.id)) {
        step1PropulsionMode(e);
      } else {
        switch(e.target.id){
          //click sur une case non séléctionnée
          case typeMaterielEnum.ENGIN:
          case typeMaterielEnum.MATERIEL:
          case typePropulsionEnum.ELECTRIQUE:
          case typePropulsionEnum.HYDROGENE:
            changeInfosAssurance(e)
            break;
          default:
            changeInfos(e, type)
            break;
        }
      }
      

    }else{
      changeInfos(e, type)
    }
  };

  const step1PropulsionMode = (e) => {
    setInformations((prevInfo) => ({
      ...prevInfo,
      [e.target.name]: prevInfo[e.target.name]?.filter(item => item !== e.target.value.trim()) ?? []
    }));
  }
  const step1TypeMode = (e) => {
    if(informations?.type?.length <= 1) {
      setInformations((prevInfo) => ({
        code: "contact-breez",
        [e.target.name]: prevInfo[e.target.name]?.filter(item => item !== e.target.value.trim()) ?? []
      }));
    } else {
      setInformations((prevInfo) => ({
        ...prevInfo,
        [e.target.name]: prevInfo[e.target.name]?.filter(item => item !== e.target.value.trim()) ?? []
      }));
    }
  }

  const handleError = () => {
    setFormError(true);
    document.getElementById("form-error")?.scrollIntoView();
  }

  const changeInfos = (e, type) => {
    setInformations((prevInfo) => ({
      ...prevInfo,
      [e.target.name]:
        type === IdentityFieldEnum.FILE ? e.target.files[0] : e.target.value.trim(),
    }));
  }

  const changeInfosAssurance = (e) => {
      setInformations((prevInfo) => ({
        ...prevInfo,
        [e.target.name]: !prevInfo[e.target.name]?.some(item => item === e.target.value.trim()) ? [...prevInfo[e.target.name] ?? [], e.target.value.trim()] : prevInfo[e.target.name] ?? []
      }));
  }

  const previous = () => {
    setStep((s) => s - 1);
  };

  const next = (type) => {
    if (type === "close") {
      close()
    }

    if (step === 2) {
      if(!isLoading) {
        setIsLoading(true);
        submit(informations).then((response) => {
          if(response?.ok) {
            setStep((s) => s + 1);
            document.querySelector(".contact").scrollTo(0,0);
          } else {
            handleError();
          }
          setIsLoading(false);
        }, () => {
          handleError();
          setIsLoading(false);
        });
      }
    } else {
      setStep((s) => s + 1);
      document.querySelector(".contact").scrollTo(0,0);
    }
  };

  const close = () => {
    setTimeout(function () {
      setShowContact(false);
    }, 600);
    const contact = document.getElementsByClassName("contact")[0];
    contact.classList.remove('contactDisplay')
    contact.classList.add('contactClose')
    document.getElementsByTagName("html")[0].classList.remove("hidden")
  }

  return (
    <div className={`contact ${isShowContact ? "contactDisplay" : ""}`}>
      <button className="contact__close" onClick={close}>
        <img src={closeIcon} alt="icone croix" />
      </button>
      {step === 1 && (
        <Step1
          informations={informations}
          handleChange={handleChange}
          setCodePostal={setCodePostal}
          next={next}
        />
      )}
      {step === 2 && (
         <Step2
         previous={previous}
         next={next}
         informations={informations}
         handleChange={handleChange}
         formError={formError}
         isLoading={isLoading}
         />
      )}
      {step === 3 && (
        <div className="step3-basic">
        <SectionContact
          title="Merci pour votre demande&nbsp;!"
          text="Nous vous recontacterons dès que possible."
          backgroundImg={backgroundSection}
        />
        <section className="step3-basic__section--2">
          <div className="step3-basic__card">
            <LinkedinCard />
            <ContactEnd step="3" hideButton={true} />
          </div>
        </section>
      </div>
      )}
    </div>
  );
}
