import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Button from "../../components/button/button";

import LayoutBreez from "../../components/layoutBreez/layoutBreez";
import SEO from "../../components/seo";

import BoxContent from "../../components/boxContent/boxContent";
import phoneIcon from "../../images/breez/phone.svg"

import "./style.scss";
import { StaticImage } from "gatsby-plugin-image";

const Breez = () => {

    const [showContact, setShowContact] = useState(false);

    return (
        <>
        <Helmet>
            <body />
        </Helmet>
        <LayoutBreez setShowContact={setShowContact} showContact={showContact} >
            <SEO title="breez" newTitleTemplate="%s · L'Auxiliaire" description="Breez par L'Auxiliaire est l’offre d'assurance dédiée aux engins et matériels zéro émission qui valorise votre engagement. Avec breez, accélérez votre transition !"/>
            <header className="breez__header">
                <div className="breez__header__background"></div>
                <h1>L’assurance des engins <br/>et matériels de chantier <br/><strong>zéro émission</strong></h1>
                <p>Accélérez votre transition écologique<br/> grâce à notre offre d’assurance<br/> professionnelle <strong>à impact responsable</strong></p>
                <div className="breez__header__button">
                  <Button text="être rappelé" onClick = {() => setShowContact(true)} icon={<img src={phoneIcon} alt="icône téléphone" className="btn__plus"/>}></Button>
                </div>
                
            </header>
            <section className="breez__unique">
                <h2>Pourquoi notre offre<br/> est-elle <strong>différente</strong>&nbsp;?</h2>
                <div className="unique__boxes">
                    <div className="unique__boxes__items">
                        <BoxContent>
                            <h3>
                                Une offre <strong>claire, lisible</strong> et <strong>sans option</strong>
                            </h3>
                            <p>
                                Nous avons conçu une offre large et performante qui inclut <strong>sans option</strong> l’essentiel des couvertures dont vous avez besoin ainsi que de nouvelles garanties inédites spécifiques aux engins zéro émission.
                            </p>
                        </BoxContent>
                        <BoxContent>
                            <h3>
                                Une offre <strong>complète</strong> et <strong>innovante</strong>
                            </h3>
                            <p>
                                Nous <strong>valorisons votre engagement</strong> en vous offrant les moyens d’accélérer votre transition environnementale grâce à notre offre de services innovants.
                            </p>
                        </BoxContent>
                        <BoxContent>
                            <h3>
                                Une offre <strong>engagée</strong> et <strong>responsable</strong>
                            </h3>
                            <p>
                                Nous accordons un <strong>système de franchise préférentiel</strong> aux entreprises alimentant leurs engins avec des sources d’énergie renouvelables.
                            </p>
                        </BoxContent>
                    </div>
                    <StaticImage 
                        src="../../images/breez/pelleteuse.webp"
                        alt="picto auxiliaire blanc"
                        placeholder="blurred"
                        layout="fixed"
                        className="unique__image"
                    />
                </div>
                <div className="callback-btn">
                    <Button text="être rappelé" onClick = {() => setShowContact(true)} icon={<img src={phoneIcon} alt="icône téléphone" className="btn__plus"/>}></Button>
                </div>
                <div className="breez__bulletpoints">
                    <h2>Votre assurance spécialisée pour les<br/> engins et matériels <strong>électriques ou<br/> à propulsion hydrogène</strong></h2>
                    <ul>
                        <li>Breez est une offre sans option, où toutes les garanties sont incluses dans une offre unique, reprenant l’intégralité des couvertures dédiées aux engins et matériels thermiques auxquelles s’ajoutent des couvertures spécifiques</li>
                        <li>Elle couvre les équipements spécifiques aux engins électriques : bornes de recharge, câbles, adaptateurs et batteries rechargeables s’ils sont endommagés concomitamment à l’engin</li>
                        <li>Elle couvre les dommages aux stations de recharge mobiles de l’hydrogène</li>
                        <li>Elle couvre les engins “rétrofités”</li>
                        <li>Elle prend en charge les frais de technicien spécialisés</li>
                        <li>Elle prend en charge les frais de recyclage et de valorisation des batteries endommagées suite à sinistre</li>
                    </ul>
                    <h3 className="u-mt-big">Et pour valoriser votre engagement en faveur de l’environnement&nbsp;!</h3>
                    <ul>
                        <li>Nous avons fait le choix d’étendre la <strong>garantie valeur à neuf à 5 ans</strong> pour vos engins et matériels “propres” couverts par breez</li>
                        <li>Nous appliquons une <strong>tarification incitative</strong> afin d’encourager l’usage des engins zéro émission</li>
                    </ul>
                </div>
            </section>
            <section className="breez__headband">
                <div className="headband__content">
                    <h2>L'Auxiliaire <br className="not-on-mobile" /> s'engage pour <br/> préserver demain.</h2>
                    <Button text="Nos actions" to="/nos-engagements-environnementaux"></Button>
                </div>
                <div className="headband__image">
                </div>
            </section>
            <section className="breez__services breez-grey-section">
                <h2>Les services pour <strong>vous <br/> accompagner dans votre <br/>transition</strong>&nbsp;!</h2>
                <div className="services__content">
                    <div className="services__items">
                        <a href="https://www.hellocarbo.com/partenaires/auxiliaire/" target="_blank" className="services__item">
                            <h3>Votre bilan carbone certifié</h3>
                            <p>Un avantage de <strong>-10%</strong> est réservé aux sociétaires de L’Auxiliaire sur la plateforme digitale de notre partenaire <strong>Carbo</strong>&nbsp;! Réalisez votre <strong>bilan carbone certifié</strong> et bénéficiez d’un conseiller à disposition pour <strong>piloter votre stratégie d’entreprise</strong> grâce à des préconisations d’actions.</p>
                            <div className="callback-btn">
                                <Button text="En savoir plus"></Button>
                            </div>
                        </a>
                        <a href="https://wecount.notion.site/Promotion-Bilan-Carbone-BTP-BTP-Rh-ne-x-L-Auxiliaire-BTP-x-WeCount-cc55d6483e624daab0b6e0398244bbbb" target="_blank" className="services__item">
                            <h3>Programme collectif BTP en stratégie climat</h3>
                            <p>En partenariat avec BTP Rhône et WeCount, acteur spécialiste en accompagnement à la transition bas-carbone, <strong>intégrez la prochaine promotion dédiée au BTP !</strong></p>
                            <div className="callback-btn">
                                <Button text="En savoir plus"></Button>
                            </div>
                        </a>
                    </div>
                    <div className="services__items">
                        <a href="https://trakmy.fr/lauxiliaire-assurance/" target="_blank" className="services__item">
                            <h3>Votre traceur GPS connecté offert</h3>
                            <p>Localisez votre engin électrique et suivez son activité grâce à l’application de notre partenaire innovant <strong>TRAKmy</strong>.</p>
                            <p>Pour toute souscription d’un contrat breez, nous vous offrons un <strong>traceur GPS connecté</strong> et vous bénéficiez d’un <strong>an d’abonnement offert</strong>.</p>
                            <div className="callback-btn">
                                <Button text="En savoir plus"></Button>
                            </div>
                        </a>
                        <a href="https://www.kiloutou.fr/impakt" target="_blank" className="services__item">
                            <h3>Pass électrification</h3>
                            <p>Intensifiez l’électrification de votre parc matériel et profitez d’un <strong>pass “tarif préférentiel”</strong> vous permettant de bénéficier de <strong>-15%</strong> sur la gamme IMPAKT (matériels et engins électriques) de notre partenaire <strong>Kiloutou</strong>.</p>
                            <p><small>(Pass valable dans les agences Kiloutou situées dans les départements 01, 38, 42, 43, 69 et 71)</small></p>
                            <div className="callback-btn">
                                <Button text="En savoir plus"></Button>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="callback-btn callback-btn-services">
                    <Button text="être rappelé" onClick = {() => setShowContact(true)} icon={<img src={phoneIcon} alt="icône téléphone" className="btn__plus"/>}></Button>
                </div>
            </section>
            <section className="breez__engagement">
                <h2>Vous vous engagez,<br/><strong> nous aussi</strong>&nbsp;!</h2>
                <div className="engagement__content">
                    <div>
                        <h3>Bonus énergies renouvelables</h3>
                        <p>Vous alimentez vos engins avec des sources d’énergie vertes&nbsp;? Vous bénéficiez d’un <strong>système de franchise préférentiel</strong>&nbsp;!</p>
                    </div>
                </div>
            </section>
            <section className="breez__sinistre">
                <h2>Et en cas de<br/><strong> sinistre</strong>&nbsp;?</h2>

                <div className="breez__sinistre__content">
                    <StaticImage 
                        src="../../images/breez/parcours_sinistre.svg"
                        placeholder="blurred"
                        className="sinistre_svg"
                        alt="Sinistre"
                    />
                        <StaticImage 
                            src="../../images/breez/parcours_sinistre_vertical.svg"
                            placeholder="blurred"
                            className="sinistre_mobile_svg"
                            alt="Sinistre"
                        />
                    
                </div>
                <div className="callback-btn">
                    <Button text="être rappelé" onClick = {() => setShowContact(true)} icon={<img src={phoneIcon} alt="icône téléphone" className="btn__plus"/>}></Button>
                </div>
            </section>
        </LayoutBreez>
        </>
    );
};

export default Breez;
