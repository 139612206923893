import React from "react";

import "./navbarBreez.scss";

import logoBreez from "../../../static/logos/logo_breez.svg";
import logoAuxiliaire from "../../../static/logos/picto_couleur.svg";

import Button from "../../components/button/button";
import phoneIcon from "../../images/breez/phone.svg"


export default function NavbarBreez({
  onClickContact,
}) {

  return (
    <nav className="navbar-breez"> 
      <div className="navbar-breez__top">
        <div className="navbar-breez__section">
          <a href="/breez/">
            <img src={logoBreez} alt="logo breez" width={181} height={44} />
          </a>
          <p>L’assurance qui accélère <br/>votre transition</p>
        </div>
        <div className="navbar-breez__section logo-aux">
            <a aria-label="Accueil" href="/">
                <img src={logoAuxiliaire} alt="logo auxiliaire" width={191} height={54} />
            </a>
        </div>
        <div className="navbar-breez__section">
            <Button 
                text="être rappelé" 
                icon={<img src={phoneIcon} 
                alt="icon téléphone"
                className="btn__plus" />}  
                onClick={() => {
                onClickContact()
                document.getElementsByTagName("html")[0].classList.add("hidden")
                }} 
            />
        </div>
      </div>
    </nav>
  );
}
