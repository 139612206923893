import React, {  useEffect } from "react";

import Contact from "../contactBreez/contactBreez";
import Footer from "../footer/footer";
import BackgroundLogo from "../backgroundLogo/backgroundLogo";
import openContactMobile from "../../images/button/open-contact-mobile.svg";

import "./layoutBreez.scss";

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
import NavbarBreez from "../navbarBreez/navbarBreez";
config.autoAddCss = false; /* eslint-disable import/first */

export default function LayoutBreez({ children, setShowContact, showContact  }) {
  
  useEffect(()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('showContact')){
      setShowContact(true)
    }
  }, [])
  
  return (
    <>
      <NavbarBreez
        onClickContact={() => setShowContact(true)}
      />
      <BackgroundLogo/>
      {showContact && <Contact setShowContact={setShowContact} isShowContact={showContact} />}
      <div className="menu-btn-mobile">
        <img src={openContactMobile} onClick={() => setShowContact(true) } alt="icone contact" />
      </div>
      <main>{children}</main>
      <Footer onClickContact={() => setShowContact(true)} />
    </>
  );
}
